import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/moviebreak_logo.webp'; // Adjust the path according to your file structure
import Coffee from '../assets/coffee';

const Home = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/search?query=${searchQuery}`);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center">
      <header className="w-full py-6 bg-gradient-to-r from-blue-500 to-indigo-500 text-white text-center text-2xl font-bold">
        MovieBreak 🚽
      </header>
      <main className="flex-grow flex flex-col items-center justify-center mt-8 w-full px-4">
        <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg text-center border border-gray-200">
          <h1 className="text-3xl font-bold mb-4">Find the Perfect Time for a Break for FREE</h1>
          <p className="mb-6 text-lg">Know when to go to the bathroom during your movie.</p>
          <button 
            onClick={() => navigate('/movies')}
            className="w-full bg-blue-600 text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition duration-300"
          >
            See current list of movies
          </button>
        </div>
      </main>
      <footer className="w-full py-6 text-center text-gray-600">
        {/* put only small coffee in the middle */}
        <div className="flex justify-center items-center">
          <div className="mr-2">Made with ☕️ by Mateusz</div>
          <div className='w-24'>
          <Coffee />
          </div>

        </div>
      </footer>
    </div>
  );
}

export default Home;
