import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import moviesData from '../movies.json'; // Adjust the path according to your file structure

const MoviePage = () => {
  const { id } = useParams();
  const movie = moviesData.movies.find(movie => movie.id === parseInt(id));
  const [currentTime, setCurrentTime] = useState(0);
  const [showSynopsis, setShowSynopsis] = useState(Array(movie.breaks.length).fill(false));
  const [intervalId, setIntervalId] = useState(null);
  const [timerStarted, setTimerStarted] = useState(false);

  useEffect(() => {
    const savedStartTime = localStorage.getItem('startTime');
    if (savedStartTime) {
      const elapsedTime = Math.floor((Date.now() - new Date(savedStartTime).getTime()) / 60000);
      setCurrentTime(elapsedTime);
      startTimer();
      setTimerStarted(true);
    }
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const savedStartTime = localStorage.getItem('startTime');
        if (savedStartTime) {
          const elapsedTime = Math.floor((Date.now() - new Date(savedStartTime).getTime()) / 60000);
          setCurrentTime(elapsedTime);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  if (!movie) {
    return <div>Movie not found</div>;
  }

  const toggleSynopsis = index => {
    setShowSynopsis(showSynopsis.map((show, i) => (i === index ? !show : show)));
  };

  const startTimer = () => {
    const interval = setInterval(() => {
      setCurrentTime(prevTime => prevTime + 1);
    }, 60000);
    setIntervalId(interval);
  };

  const handleStart = () => {
    const startTime = new Date();
    localStorage.setItem('startTime', startTime);
    setCurrentTime(0);
    startTimer();
    setTimerStarted(true);
  };

  const handleCancel = () => {
    clearInterval(intervalId);
    localStorage.removeItem('startTime');
    setCurrentTime(0);
    setTimerStarted(false);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center relative">
      <div
        className="absolute inset-0 bg-cover bg-center opacity-20"
        style={{ backgroundImage: `url(${movie.logo})` }}
      ></div>
      <header className="w-full py-6 bg-gradient-to-r from-blue-500 to-indigo-500 text-white text-center text-2xl font-bold z-10 relative">
        <Link to="/">MovieBreak 🚽</Link>
      </header>
      <main className="flex-grow flex flex-col items-center justify-center mt-8 w-full z-10 relative">
        <div className="w-full max-w-xl p-4 bg-white bg-opacity-90 rounded-lg shadow-lg text-center">
          <h1 className="text-3xl font-bold mb-2">{movie.title}</h1>
          <div>
            <p className="text-lg mb-2">Ratings:</p>
            <p className="mb-2">
              <a href={movie.imdbLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                IMDb: {movie.imdbRating}/10
              </a>
            </p>
            <p className="mb-4">
              <a href={movie.rtLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                Rotten Tomatoes: {movie.rtRating}%
              </a>
            </p>
            <p className="text-lg mb-2">Movie is {movie.duration} long</p>
            <p className="mb-6 text-sm">{movie.description}</p>
            {!timerStarted && (
              <>
                <p className="text-gray-700 font-semibold mb-2">Click below to start the timer and see break times update!</p>
                <button
                  onClick={handleStart}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md mb-4 hover:bg-blue-600"
                >
                  Start
                </button>
              </>
            )}
            {timerStarted && (
              <>
                <button
                  onClick={handleCancel}
                  className="bg-red-500 text-white px-4 py-2 rounded-md mb-4 hover:bg-red-600"
                >
                  Cancel Timer
                </button>
                <p className="text-red-500 font-semibold mb-4">Canceling will reset the timer!</p>
                <p className="text-gray-700 font-semibold mb-4">Current Time: {currentTime} min</p>
              </>
            )}
            <h2 className="text-2xl font-bold mb-4">Pee Breaks:</h2>
            {movie.breaks.map((breakInfo, index) => (
              <div key={index} className="mb-4">
                <p className="text-xl font-semibold">Break {index + 1} in {breakInfo.start - currentTime} min</p>
                <p className="text-gray-600 font-semibold">Duration: {breakInfo.duration} min</p>
                <p className="text-gray-600 font-semibold">Leave when: <span className='font-normal'>{breakInfo.leaveWhen}</span></p>
                <button
                  onClick={() => toggleSynopsis(index)}
                  className="text-blue-500 hover:underline mb-2"
                >
                  {showSynopsis[index] ? 'Hide Synopsis' : 'Show Synopsis'}
                </button>
                {showSynopsis[index] && <p className="text-gray-600 font-normal text-sm">{breakInfo.synopsis}</p>}
              </div>
            ))}
          </div>
          <Link to="/movies" className="text-blue-500 hover:underline mt-4 block">
            Back to movies list
          </Link>
        </div>
      </main>
    </div>
  );
}

export default MoviePage;
