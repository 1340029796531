import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import data from "../movies.json";

const SearchResults = () => {
   
  const location = useLocation();
//   const query = new URLSearchParams(location.search).get('query');
  const [movies, setMovies] = useState(
    data.movies
  );

//   useEffect(() => {
//     // Mock API call to fetch movies based on query
//     const fetchMovies = async () => {
//       const results = [
//         { id: 1, title: 'Dune', breaks: 2, logo: 'https://link.to/dune_logo.jpg' },
//         { id: 2, title: 'Long Movie Title', breaks: 1, logo: 'https://link.to/long_movie_logo.jpg' },
//       ];
//       setMovies(results);
//     };

//     fetchMovies();
//   }, [query]);
//     }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center">
      <header className="w-full py-6 bg-gradient-to-r from-blue-500 to-indigo-500 text-white text-center text-3xl font-bold z-10 relative">
        <Link to="/">MovieBreak 🚽</Link>
      </header>
      <main className="flex-grow flex flex-col items-center justify-center mt-8 w-full">
        <div className="w-full max-w-2xl p-8 bg-white rounded-lg shadow-lg text-center">
          {/* <h1 className="text-3xl font-bold mb-6">Search Results for "{query}"</h1> */}
            <h1 className="text-3xl font-bold mb-6">Movies</h1>
          {/* {movies.length > 0 ? ( */}
          {
movies.map(movie => (
    <div key={movie.id} className="mb-6 flex flex-row items-center justify-between p-2 border-b border-gray-200">
      <div className="flex items-center space-x-2">
        <img src={movie.logo} alt={`${movie.title} logo`} className="w-24 h-24 object-contain" />
        <div className="text-left">
          <Link to={`/movie/${movie.id}`} className="text-blue-500 text-2xl font-semibold hover:underline">
            {movie.title}
          </Link>
          <p className="text-gray-600">Breaks: {movie.breaks.length}</p>
        </div>
      </div>
      <div className="flex flex-col items-end space-y-2">
        <a href={movie.imdbLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
          IMDb: {movie.imdbRating}/10
        </a>
        <a href={movie.rtLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
          Rotten Tomatoes: {movie.rtRating}%
        </a>
      </div>
    </div>
  ))
            }
          {/* ) : (
            <p className="text-gray-600">No results found</p>
          )} */}
        </div>
      </main>
    </div>
  );
}

export default SearchResults;
