function Coffee() {
    return (
    <a
      className="buyButton"
      target="_blank"
      href="https://www.buymeacoffee.com/mateuszcoder"
    >
      <img
        className="coffeeImage"
        src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
        alt="Buy me a coffee"
      />
      
    </a>
     );
    }

export default Coffee;